import React, { memo } from 'react';
import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import { useFilterState } from '../filterContext';
import { useCapUtils } from '../hooks/useCapUtils';
import Button from '../../global/atoms/button';
import { capDataLocator } from '../dataLocator';
import { EDIT_VIEW_CLOSE } from '../constants';

const CapHero = ({ saveCapDetailsHandler, isStoreLocationFetching, renderRentalDetailsView }) => {
    const capIntl = useIntl();
    const [{ isEditView }, dispatch] = useFilterState();
    const { getCapUserDetails } = useCapUtils();

    const capHeaderOverlayHandler = () => {
        dispatch({ type: EDIT_VIEW_CLOSE });
        saveCapDetailsHandler(false, true);
    };

    const renderUpdateBtn = () => {
        const showShimmer = isStoreLocationFetching && isEditView;
        const startRentingBtnText =
            getCapUserDetails()?.rentingCTAText || capIntl.formatMessage({ id: 'cap:start-renting-button' });
        return (
            <Button
                type="button"
                className={`start-renting-btn ${showShimmer ? 'shimmer start-renting-btn__shimmer' : ''}`}
                buttonAriaLabel={startRentingBtnText}
                dataTestid={capDataLocator.renting_button_cta_testid}
                isdisabled={showShimmer}
                onClick={() => saveCapDetailsHandler(false)}>
                {startRentingBtnText}
            </Button>
        );
    };

    return (
        <>
            <button
                className={`modal__root ${isEditView ? 'modal__root-active' : 'modal__root-inactive'}`}
                onClick={capHeaderOverlayHandler}
            />
            <div className={'cap-header'}>
                {renderRentalDetailsView()}
                <div className="start-renting-div">{renderUpdateBtn()}</div>
            </div>
        </>
    );
};

CapHero.propTypes = {
    isStoreLocationFetching: bool,
    saveCapDetailsHandler: func,
    renderRentalDetailsView: func
};

CapHero.defaultProps = {
    isStoreLocationFetching: false,
    saveCapDetailsHandler: () => {},
    renderRentalDetailsView: () => {}
};

export default memo(CapHero);
