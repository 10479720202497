import React, { memo, useEffect, useRef, useState } from 'react';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useCartState } from '../../../../contexts/cart';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import { usePricing } from '../../../../hooks/usePricing';
import Carousel from '../alternateInventory/carousel/Carousel';
import AddToCartProductTileReact from '../productTile/addToCartProductTileReact/AddToCartProductTileReact';
import { getDataBricks, setAccessTokenSession } from './api/getDataBricks';
import { recommendationMobileResponsive } from '../../utils/commonUtils';
import '../alternateInventory/carousel/carousel.scss';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { logError } from '../../utils/logger';
import { dataBricksConstant } from './dataBricksConstant';

const DataBricks = () => {
    const userType = useCheckUser();
    const [{ userAccount, cidPcList }] = useCartState();
    const [productRecommmendationsData, setProductRecommmendationsData] = useState([]);
    const { getProductPrices } = usePricing();
    const [{ isProfileLoaded }] = useUserContext();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();
    const productRecommmendationsRef = useRef('null');
    const itemLength = productRecommmendationsData?.length || dataBricksConstant?.defaultItemLength;
    const productLenght = productRecommmendationsData?.length;

    useEffect(() => {
        const fetchProductRecommendations = async () => {
            try {
                await setAccessTokenSession();
                const { data: { data: { productRecommmendations = [] } = {} } = {} } = await getDataBricks(
                    userAccount?.accountNumber
                );

                // will remove productRecommmendationsJson onces have product.ec_pc_inventory as a string from API
                const productRecommmendationsJson = productRecommmendations?.map(product => {
                    return {
                        ...product,
                        ec_pc_inventory: JSON.stringify(product?.ec_pc_inventory || {})
                    };
                });

                setProductRecommmendationsData(productRecommmendationsJson);
                
            } catch (error) {
                logError(error, false, 'fetchProductRecommendations');
            }
        };
        fetchProductRecommendations();
    }, [userAccount?.accountNumber]);

    useEffect(() => {
        try {
            const fetchRates = async() => {
                await getProductPrices(productRecommmendationsData, VARIABLE_CONFIG?.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE);
            };
            
            if (isProfileLoaded) {
                if (isRentalDetailsAvailable() && cidPcList?.length > 0 && productRecommmendationsData?.length > 0) {
                    fetchRates();
                }
            }
        } catch (error) {
            logError(error, false, 'fetchProductRecommendations');
        }
    }, [cidPcList, isProfileLoaded, productRecommmendationsData]);

    const renderCarouselItems = productRecommmendationsData => {
        return productRecommmendationsData?.map(singleRecommendation => {
            const {
                catclass,
                primary_image_url,
                producttitle,
                producturl,
                metadescription,
                metatitle,
                ec_pc_inventory,
                disableaddtocartoption,
                showonlinecatalog
            } = singleRecommendation;

            let cardData = {
                catclass: catclass,
                productimageurl: primary_image_url,
                producttitle: producttitle,
                producturl: producturl,
                metadescription: metadescription,
                metatitle: metatitle,
                catsku: catclass,
                inventory: ec_pc_inventory,
                disableaddtocartoption: disableaddtocartoption,
                showonlinecatalog: showonlinecatalog
            };
            return <AddToCartProductTileReact key={catclass} cardData={cardData} hideUnavailable={true} />;
        });
    };

    useEffect(() => {
        if (productRecommmendationsRef) {
            if (productLenght <= dataBricksConstant?.minProductLenght) {
                productRecommmendationsRef?.current
                    ?.closest('.productrecommendations')
                    ?.classList.remove('productrecommendations--show');
            } else {
                productRecommmendationsRef?.current
                    ?.closest('.productrecommendations')
                    ?.classList.add('productrecommendations--show');
            }
        }
    }, [productLenght]);

    return (
        <div ref={productRecommmendationsRef} >
            {userType === USER_TYPE.CREDIT && (
                <Carousel
                    disableDotsControls={false}
                    totalItem={itemLength}
                    mobileResponsive={recommendationMobileResponsive()}
                    carouselWrapClasses={'carousel--home-test'}>
                    {renderCarouselItems(productRecommmendationsData)}
                </Carousel>
            )}
        </div>
    );
};
export default memo(DataBricks);
