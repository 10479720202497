import React, { memo, useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useCartState } from '../../../../../contexts/cart';
import { useAtp } from '../../../../cap/hooks/useATP';
import { useDidMount } from '../../../../../hooks/useDidMount';
import { usePageType } from '../../../../../hooks/usePageType';
import useAnalytics from '../../../../../hooks/useAnalytics';
import ProductTile from '../ProductTile';
import { VARIABLE_CONFIG } from '../../../../../constants/analyticsConstants/Variables';
import { isValidString } from '../../../utils/logger';

const AddToCartProductTile = props => {
    const [{ staticTileProductDetails }] = useCartState();
    const currentNode = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const { handleATP } = useAtp();
    const didMount = useDidMount();
    const [productCardData, setProductCardData] = useState({});
    const [productCardIndex, setProductCardIndex] = useState('');
    const [listName, setlistName] = useState('');
    const pageType = usePageType();
    const isHomePage = pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
    const [{ sendAnalyticsForProductCardEvent, sendAddToCartAnalytics }] = useAnalytics();

    useEffect(() => {
        if (currentNode?.current) {
            setProductCardData(currentNode?.current?.parentElement?.dataset);
            setProductCardIndex(currentNode?.current?.parentElement?.parentElement?.dataset?.slickIndex);
            const containerAncestor = currentNode?.current?.closest('.container');
            if (containerAncestor) {
                const firstChild = containerAncestor?.children[0]?.firstElementChild;
                if (firstChild) {
                    setlistName(firstChild?.textContent?.trim());
                }
            }
        }
    }, [didMount]);

    let cardData = {};
    try {
        cardData = productCardData?.productdata ? JSON.parse(productCardData?.productdata) : {};
        const altText = productCardData?.productalttext ? isValidString(productCardData?.productalttext) : "";
        cardData.productAltText = altText;
        cardData.index = parseInt(productCardIndex || 0);
        cardData.listName = listName;
    } catch (e) {
        productCardData?.productdata ? cloneDeep(productCardData?.productdata) : {};
    }

    let tileStatus;
    if (staticTileProductDetails?.length > 0) {
        staticTileProductDetails?.forEach(product => {
            if (product?.catclass === cardData?.catclass) {
                tileStatus = handleATP({
                    catsku: cardData?.catclass,
                    inventoryDataObj: product?.ec_pc_inventory,
                    showonlinecatalog: cardData?.showonlinecatalog,
                    disableaddtocartoption: cardData?.disableaddtocartoption,
                    pcAvailability: product?.pc_availability
                });
            }
        });
    } else {
        tileStatus = handleATP({
            catsku: cardData?.catclass,
            showonlinecatalog: cardData?.showonlinecatalog,
            disableaddtocartoption: cardData?.disableaddtocartoption,
            pcAvailability: cardData?.pc_availability
        });
    }

    const addToCartClickAnalytics = () => {
        sendAddToCartAnalytics(cardData);
    };

    return (
        <div ref={currentNode} className={isLoading ? 'pdp__root-isloading' : ''}>
            <ProductTile
                tileDetails={cardData}
                tileState={tileStatus}
                trackAnalytics={sendAnalyticsForProductCardEvent}
                handleAddToCartClickAnalytics={addToCartClickAnalytics}
            />
        </div>
    );
};

export default memo(AddToCartProductTile);
