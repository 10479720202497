import { bool, func, node, oneOf, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import AlertTriangle from '../../../../resources/images/alert-triangle.svg';
import AlertSuccess from '../../../../resources/images/checkmark-circle.svg';
import CloseButton from '../../../../resources/images/close.svg';
import AlertIcon from '../../../../resources/images/error.svg';
import { ALERT_SUCCESS, ALERT_TRIANGLE } from '../../constants';
import Button from '../button/button';
import './alert.scss';

const Alert = props => {
    const intl = useIntl();
    const {
        type,
        message,
        localStyle,
        dataTestId = '',
        icon,
        id,
        customClass,
        showCloseButton,
        onClose,
        showLink,
        linkLabel,
        linkUrl,
        tabIndex,
        anchorTagName,
        noRole
    } = props;
    const alertType = {
        error: 'alert-error',
        warning: 'alert-warning',
        success: 'alert-success'
    };

    const renderIcon = () => {
        if (typeof icon === 'string') {
            return (
                <>
                    {icon === ALERT_SUCCESS ? (
                        <AlertSuccess tabIndex={'-1'} aria-hidden={true} />
                    ) : icon === ALERT_TRIANGLE ? (
                        <AlertTriangle tabIndex={'-1'} aria-hidden={true} />
                    ) : (
                        <AlertIcon tabIndex={'-1'} aria-hidden={true} />
                    )}
                </>
            );
        } else {
            return icon;
        }
    };

    const anchorTag = () => (
        <>
            {message}{' '}
            <a href="#unavailable-rentals" className="cart-page__viewdetails">
                {' '}
                {anchorTagName}{' '}
            </a>
        </>
    );

    return (
        <div
            role={noRole ? '' : 'alert'}
            className={`alert ${alertType[type]} ${localStyle} ${customClass}`}
            data-testid={dataTestId}
            tabIndex={tabIndex || '0'}
            id={id}>
            {renderIcon()}
            <div className={`${anchorTagName ? '' : 'alert_message_container'}`}>
                {anchorTagName ? anchorTag() : message}
                {showLink && (
                    <a className="alert_link" href={linkUrl} data-testid="wishlistLink">
                        {linkLabel}
                    </a>
                )}
            </div>

            {showCloseButton && (
                <Button
                    type="button"
                    className="button button-pb0 close_icon"
                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                    title={'close_button'}
                    tabIndex={0}
                    onClick={onClose}>
                    <CloseButton tabIndex={-1} aria-hidden="true" />
                </Button>
            )}
        </div>
    );
};
Alert.propTypes = {
    type: oneOf(['error', 'warning', 'success']).isRequired,
    message: node,
    localStyle: string,
    id: string,
    role: oneOf(['alert', 'none']),
    showCloseButton: bool,
    showLink: bool,
    linkLabel: string,
    linkUrl: string
};

Alert.defaultProps = {
    role: 'alert',
    localStyle: '',
    id: '',
    showCloseButton: false,
    showLink: false,
    linkLabel: '',
    linkUrl: ''
};
export default Alert;
