import React, { memo } from 'react';
import { func, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useCartState } from '../../../../contexts/cart/cartContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import useAnalytics from '../../../../hooks/useAnalytics';
import AccountSelector from './AccountSelector';
import PlainText from './PlainText';
import { isCheckoutPage } from '../../utils/commonUtils';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { SET_CART_OVERLAY } from '../../../../aem-core-components/actions/constants';
import { CART_OVERLAYS } from '../../../../constants/cartConstants';
import { EMPLOYEE } from '../../../checkout/constants';

const AccountDetails = props => {
    const {
        setShowAccountModal,
        handleAccountOverlay,
        accountLength,
        screenName,
        accountName,
        accountNumber,
        accountSelectorTitle
    } = props;
    const intl = useIntl();
    const [, dispatch] = useCartState();
    const [userState] = useUserContext();
    const { userProfile } = userState;
    const { sendEventsForLocationAction } = useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();

    const navigationHandler = cartOverlay => {
        //track navigation to Accounts for credit user
        if (userProfile?.type != EMPLOYEE) {
            if (setShowAccountModal) {
                setShowAccountModal(true);
            } else if (handleAccountOverlay) {
                handleAccountOverlay(true);
            } else {
                dispatch({ type: SET_CART_OVERLAY, cartOverlay });
            }
        }
        if (
            JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) &&
            !isCheckoutPage()
        ) {
            sendEventsForLocationAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_ACCOUNT,
                payloadEcommerceLocationActionAnalytics()
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    };

    if (accountLength > 1) {
        return (
            <AccountSelector
                screenName={screenName}
                accountName={accountName}
                accountNumber={accountNumber}
                accountClick={() => navigationHandler(CART_OVERLAYS.ACCOUNT)}
                buttonAriaLabel={`${intl.formatMessage({ id: 'account:ac-minicart-choose-account-text' })}`}
                accountSelectorTitle={accountSelectorTitle}
            />
        );
    }
    return <PlainText accountName={accountName} accountNumber={accountNumber} />;
};

AccountDetails.propTypes = {
    setShowAccountModal: func,
    handleAccountOverlay: func,
    accountLength: string,
    screenName: string,
    accountName: string,
    accountNumber: string,
    accountSelectorTitle: string
};

AccountDetails.defaultProps = {
    setShowAccountModal: () => {},
    handleAccountOverlay: () => {},
    accountLength: '',
    screenName: '',
    accountName: '',
    accountNumber: '',
    accountSelectorTitle: ''
};

export default memo(AccountDetails);
