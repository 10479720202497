import React from 'react';
import { defaultEngineOptions, productsuggestion_engine, relatedInfoSugesstions } from './Engine';
import './search.scss';
import Typeahead from './typahead';
import BlockSearch from './blockSearch';

// @ts-ignore
const {
    buildOtherSuggestion,
    buildFieldSuggestion,
    buildCustomStandaloneSearchBox
} = require('./browser/headless_extension_bundle.js');
const { buildSearchEngine, buildContext, buildTab } = require('@coveo/headless');

/* OPTIONS: searchbox parameters */
const sbOptions = {
    numberOfSuggestions: 5,
    highlightOptions: {
        exactMatchDelimiters: {
            open: '<mark>',
            close: '</mark>',
        },
    }
}

/**
 * set context
 */

const setContext = (engine) => {
    const context = buildContext(engine);
    const companyID = localStorage.getItem("companyID") || 1;
    if (companyID && companyID == 2) {
        context.add("website", "en_CA");
    } else {
        context.add("website", "en_US");
    }
}

/* OPTIONS: standalone searchbox parameters */
const standaloneSbOptions = {
    ...sbOptions,
    redirectionUrl: 'http://placeholder.com'
}

/* Product Suggestion add-on: to be assigned to the searchbox object 
    - This is "Search" approach. See lower the "Field Value" approach 
    - Search approach is searching in the title, description, etc.. .of the idnex record
*/const rentals_tab = buildTab(productsuggestion_engine, {
    options: {
        id: 'RentalsTab',
        expression: ''
    },
    initialState: {
        isActive: true
    }
})
setContext(productsuggestion_engine)
const productFilterExpression = '@source=="Product Catalog"';
const productsuggestion_groupname = 'ProductSuggestions';

const productSuggestions = buildOtherSuggestion(productsuggestion_engine, productsuggestion_groupname, 'buildResultList', null, { options: { fieldsToInclude: ['ec_product_url', 'ec_skus'] } });


/* RelatedContent Suggestion add-on: to be assigned to the searchbox object */
const relatedcontentsuggestion_engine = buildSearchEngine(relatedInfoSugesstions);
setContext(relatedcontentsuggestion_engine)
const relatedcontentFilterExpression = null;
const relatedcontentsuggestion_groupname = 'RelatedContentSuggestions';
const relatedcontentSuggestions = buildOtherSuggestion(relatedcontentsuggestion_engine, relatedcontentsuggestion_groupname, 'buildResultList', relatedcontentFilterExpression, { options: { fieldsToInclude: ['sfid'] } });

/* 
  Category Suggestion add-on: to be assigned to the searchbox object 
    - fetches values from jpderytest_category_l3 field - generated by IPE "jpdery_test_categoriesextendedfields" by splitting value of "categories" field from catalog
*/
const { organizationEndpoints, accessToken, organizationId, searchHub } = defaultEngineOptions.configuration;
const catl2_Suggestions = buildFieldSuggestion('L2Categories', organizationEndpoints?.platform, accessToken, organizationId, searchHub, 'categoryl2data', null, false, '::');

/* Product Suggestion add-on: to be assigned to the searchbox object 
    - This is "Field Value" approach, different than above "Sarch" approach 
    - FielValue approach only saerc on title; uses a "trailing wildcard" matching pattern e.g. use types `dingo t`, component will  effectively search for `dingo t*`
*/
const productNameSuggestions = buildFieldSuggestion(productsuggestion_groupname, organizationEndpoints?.platform, accessToken, organizationId, searchHub, 'product_name_suggestions', '@ec_show_onlinecatalog=1', true, '::');


/* buildCustomStandaloneSearchBox  */
const engine_for_standalonesearchbox = buildSearchEngine(defaultEngineOptions);
setContext(engine_for_standalonesearchbox)

const search_based_addons = [productSuggestions, relatedcontentSuggestions]
const field_based_addons = [catl2_Suggestions]

export const myCustomStandaloneSearchBox = buildCustomStandaloneSearchBox(engine_for_standalonesearchbox, { options: standaloneSbOptions }, search_based_addons, field_based_addons)


export const BlogStandaloneSearchBox = buildCustomStandaloneSearchBox(engine_for_standalonesearchbox, { options: standaloneSbOptions }, search_based_addons, field_based_addons)


export function SearchTypeAhead() {
    return <Typeahead myCustomStandaloneSearchBox={myCustomStandaloneSearchBox} engine={engine_for_standalonesearchbox} />
}

export function BlockSearchTypeAhead() {
    return <BlockSearch myCustomStandaloneSearchBox={BlogStandaloneSearchBox} engine={engine_for_standalonesearchbox} searchplaceholderlabel="hello this is placeholder" />
}