import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import config from '../../App/config';
import { logError } from '../../global/utils/logger';
import { getAuthToken, sendOtpToEmail } from '../api/apiRequest';
import { CASH_FLOW_PATH, ERROR_SCREEN, SUCCESS_CODES } from '../constants';

export const navigateToPath = (navigate, path) => {
    navigate(`${config.pagePaths.guidedRegWorkflow}${path}`);
};

export const getEmailVerificationObject = () => {
    try {
        const { GR_CREATE_ACCOUNT: { emailverificationconfigs = '' } = {} } = ENV_CONFIG;
        return JSON.parse(emailverificationconfigs);
    } catch (error) {
        logError(error);
        return {};
    }
};

export const getCreateAccountObject = () => {
    try {
        const { GR_CREATE_ACCOUNT: { createaccountconfigs = '' } = {} } = ENV_CONFIG;
        return JSON.parse(createaccountconfigs);
    } catch (error) {
        logError(error);
        return {};
    }
};

export const getBasicAccountScreenConfigs = () => {
    try {
        const { GR_CREATE_ACCOUNT: { basicaccountscreenconfigs = '' } = {} } = ENV_CONFIG;
        return JSON.parse(basicaccountscreenconfigs);
    } catch (error) {
        logError(error);
        return {};
    }
};

export const addFormReloadWarning = isFormDirty => {
    window.onbeforeunload = () => {
        if (isFormDirty) {
            return 'You have unsaved changes. Are you sure you want to leave this page?';
        }
    };

    return () => {
        window.onbeforeunload = null;
    };
};

export const refreshTokenCall = async () => {
    try {
        const { data: { access_token: token } = {}, error: authError = {} , status: status} = (await getAuthToken()) || {};
        if (status === SUCCESS_CODES.SUCCESS_CODE_200) {
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.GR_TOKEN, token);
        } else {
            throw new Error(authError);
        }
    } catch (error) {
        logGRError(error);
    }
};

export const resendVerifyCode = async email => {
    try {
        await refreshTokenCall();
        const { data, status, error } = await sendOtpToEmail(email);
        return { data, status, error };
    } catch (error) {
        logGRError(error);
        return { data: null, error: error, status: null };
    }
};

export const redirectToCashFlow = email => {
    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.GR_EMAIL, email);
    const url = window?.location?.protocol + '//' + window?.location?.host + CASH_FLOW_PATH;
    window.location.href = url;
};

export const logGRError = error => {
    logError(error);
    window.location.href = ERROR_SCREEN;
};

export const clearCache = () => {
    sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CCA_SUBMISSION_ID);
    sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.CLIENT_IP_ADDRESS);
    sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.GR_TOKEN);
};
